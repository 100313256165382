import React from "react"

class QuestionnaireResult extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        const resultString = this.props.result;

        const showQr = this.props.qrCode;

        // styles
        const divCard = {
            maxWidth: "450px",
            maxHeight: "600px",
            background: "#1b3349 none repeat scroll 0% 0%",
            borderRadius: "4px",
            minHeight: "400px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            padding: "10px"
        }
        const textCard = {
            textAlign: "center",
            paddingTop: "10px",
            color: "white"
        }
        // html
        return (
            <>
                <div style={divCard} >
                    <p style={textCard}>{resultString}</p>
                    { showQr && 
                    <img src={'data:image/png;base64, ' +  this.props.qrCode} /> }
                </div>
            </>
        )
    }
}

export default QuestionnaireResult