import React, { useState, useEffect, useContext } from "react"
import { API, graphqlOperation } from 'aws-amplify'
import Question from "./question"
import { createQuestionnaireAndResult } from "../graphql/mutations"
import * as buttonStyles from './button.module.css'
import { LoadMaskContext }  from "../provider/loadmaskprovider"
import TermsOfService from './terms-of-service'
import * as alertStyles from '../alert.module.css'
import Webcam from "react-webcam";

const Questionaire = (props) => {
  const [questionState, setQuestionState] = useState({})
  const [imageState, setImageState] = useState({})
  const [answerState, setAnswerState] = useState({})
  const [validState, setValidState] = useState(false);
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [disclaimer, setDisclaimer] = useState(null)

  const getCodeTranslations = /* GraphQL */`
  query GetCodeTranslations($tenant: ID!, $language: String!) {
    getCodeTranslations(tenant: $tenant, language: $language) {
      tenant
      language
      disclaimer
      questions {
        id
        question
      }
      analyticResults {
        id
        answer
        proceed
      }
    }
  }`

  const getQuestions = async(tenant) => {
    try {
      setIsLoading(true)
      let result = await API.graphql(graphqlOperation(getCodeTranslations, { tenant: props.tenant.name, language: "eng"} ));
      if(typeof result['data']['getCodeTranslations'] != 'undefined') {
        setDisclaimer(result['data']['getCodeTranslations']['disclaimer']);
        let questions = result['data']['getCodeTranslations']['questions'];
        let questionsMap = {};
        for(let i = 0; i < questions.length; i++) {
          const question = questions[i];
          questionsMap[question['id']] = question['question']
        }
        setQuestionState(questionsMap);
        const answers = [];
        for(let questionNumber in questionsMap) {
          answers[questionNumber] = undefined;
        }
        setAnswerState(answers);
      }
      console.log(result);
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    getQuestions(props.tenant.name)
  },[props.tenant])

  useEffect(() => {
    let valid = true;
    if(props.tenant.name){
      for(let q in answerState) {
        if(answerState[q] == undefined) {
          valid = false;
          break;
        }
      }
    }else{
      valid = false;
    }
    if(!imageState.image)
      valid = false;

    setValidState(valid);
  },[answerState, imageState])

  const handleUpdate = (value, questionid) => {
    setAnswerState({
      ...answerState,
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Object_initializer#Computed_property_names
      [questionid]:value
    })
  }

  const handleSnapshot = (imageString) => {
    setImageState({
      ...imageState,
      image: imageString
    })
    console.log(imageString)
  }

  const handleSubmit = async event => {
    setIsLoading(true);
    event.preventDefault()
    let imageString = imageState.image
    var finalQuestions = []
    for(var questionNumber in answerState){
      finalQuestions.push({id: questionNumber, question: questionState[questionNumber], answer: answerState[questionNumber]});
    }
    //FIXME tenant needs to be replaced with the appropriate value when DET-121 is completed
    var questionairObj = {'questions': finalQuestions, tenant: props.tenant.name, reporters: ["webpage"], facial: imageState.image}
    var result = await API.graphql(graphqlOperation(createQuestionnaireAndResult, {input: questionairObj}));
    console.log(result);
    props.resultHandler(result.data.createQuestionnaireAndResult);
    setIsLoading(false);
  }

  var renderQuestions = []
  for(let questionNumber in questionState) {
    let question = questionState[questionNumber];
    // this is why it is duped https://stackoverflow.com/a/33683036/1474991
    renderQuestions.push(<Question checked={answerState[questionNumber]} key={props.tenant.name + '_' + questionNumber} questionid={questionNumber} label={question} question={question} onAnswer={handleUpdate}/>)
  }
  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user"
  };

  return (<>
    <h1>{props.tenant.prettyName} Health Questionnaire</h1>
    <div style={{textAlign: "center"}}>
        <img style={{display: "inline-flex", width: '320px', textAlign: 'center', margin: "0px"}} src={imageState.image} alt="You must submit a photo!" />
        <Webcam
            style={{diplay: "inline-flex"}}
            audio={false}
            screenshotFormat="image/jpeg"
            width={320}
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <div style={{width: "100%"}}><button style={{display: "block", margin: "auto"}} className={buttonStyles.button}
                onClick={() => {
                  const imageSrc = getScreenshot()
                  handleSnapshot(imageSrc)
                }}
              >
                Capture photo
              </button></div>
            )}
          </Webcam>
      </div>
    <form
        method="post"
        onSubmit={event => {
            handleSubmit(event)
        }}
    >
      { disclaimer && 
        <div className={`${alertStyles.alert} ${alertStyles.info}`}>
            <p style={{fontStyle:"italic", marginBottom:"0"}}>{disclaimer}</p>
        </div>
      }
        {renderQuestions}
       
        <div style={{textAlign:'center', margin:'30px'}}>
          <button disabled={!validState} className={buttonStyles.button} type="submit" value="Submit Form">Submit Form</button>
        </div>
    </form>
    
  </>)
}

export default Questionaire