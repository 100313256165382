import React, { useEffect, useContext, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

import Questionaire from "../components/questionaire"
import QuestionnaireResult from "../components/questionnaire-result"
import CustomAuth from "../components/custom-auth"
import { UserContext, AuthContext } from "../provider/usercontextprovider"
import { API, graphqlOperation } from "aws-amplify"
import { searchQuestionnaires } from "../graphql/queries"
import { LoadMaskContext } from "../provider/loadmaskprovider";

const IndexPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const [authState, setAuthState] = useContext(AuthContext);
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [state, setState] = useState({currentTenant:{name:null}})

  const getTenant = `
    query GetTenant($name: ID!) {
      getTenant(name: $name) {
        name
        prettyName
        questionnaireValidityTimeInSeconds
      }
    }
  `;

  async function loadTenant() {
    try {
      setIsLoading(true)
      let localState = {}
      
      const tenantName = window.location.host.indexOf('.') > 0 ? window.location.host.split('.')[0] : '';
      const result = await API.graphql(graphqlOperation(getTenant, {
        name: tenantName
      }));
      console.log(result);
      const tenant = result.data.getTenant
      localState.currentTenant = tenant

      if(tenant.name && !state.bypass) {
        let questionnaireAndResult = await getLastQuestionnaire(tenant)
        localState.results = questionnaireAndResult
        localState.bypass = questionnaireAndResult == null
        setState(localState)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function getLastQuestionnaire(tenant){

    const result = await API.graphql(graphqlOperation(searchQuestionnaires, { 
      // We need to add a filter just in case you're an admin and see everything...
      filter: { 
        owner: { 
          eq: userContext.username
        },
        tenant: {
          eq: tenant.name
        }
      },
      limit: 1,
      nextToken: null,
      sort: { 
        field:"screeningDate", 
        direction:"desc" 
      } 
    }));
    console.log(result.data.searchQuestionnaires.items);
    if(result.data.searchQuestionnaires.items.length > 0){
      const currentTimeInSeconds = Math.round(new Date().getTime() / 1000);
      const questionnaireAndResult = result.data.searchQuestionnaires.items[0];
      console.log(tenant.questionnaireValidityTimeInSeconds)
      const questionnaireDate = new Date(questionnaireAndResult.screeningDate);
      console.log(questionnaireDate.getTime());
      if( ( currentTimeInSeconds - Math.round( questionnaireDate.getTime() / 1000) ) < tenant.questionnaireValidityTimeInSeconds ){
        console.log(`Within validity time of ${tenant.questionnaireValidityTimeInSeconds} seconds`);
        return questionnaireAndResult
      }
    }
    return null
  }

  function resultHandler(results){
    setState({
      ...state,
      results: results,
      bypass: true
    })
  }

  useEffect(()=>{
    if(userContext.username){
      loadTenant()
    }
  },[userContext.username])

  useEffect(()=>{
    console.log(authState);
    if(authState == "signin"){
      setState({
        ...state,
        results: null,
        bypass: false,
        currentTenant: { name: null }
      })
    }
  },[authState])

  if(state.currentTenant.name){
    return (
      <CustomAuth>
        <SEO title={state.currentTenant.prettyName} />
        {state.results
          ? <div>
              <div style={{justifyContent:"center", display:"flex"}}>
                <QuestionnaireResult qrCode={state.results.qrcode} result={state.results.result} />
              </div>
              <div style={{textAlign:"center"}}><a href="#" onClick={() => {setState({...state, results: null, bypass: true})}}>Retake Questionnaire</a></div>
            </div>
          : <Questionaire tenant={state.currentTenant} resultHandler={resultHandler}/>
        }
      </CustomAuth>
    )
  }
  else{
    return (
      <CustomAuth>
        <SEO title="" />
      </CustomAuth>
    )
  }

}
export default IndexPage
