import React from "react"

class Question extends React.Component {
  state = {
    answer: -1
  }

  handleChange = event => {
    this.setState({answer: event.target.value == 'Yes' ? 1 : 0})
    this.props.onAnswer(event.target.value == 'Yes', this.props.questionid)
  }
  render() {
    return (
      <>
        <div style={{ padding: "30px 10px 30px 10px", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "rgb(205,205,205)", display: "flex" }}>
          <div style={{ flex: 1 }}>
            <label>
              <span style={{ verticalAlign: 'middle', fontWeight: 'normal', marginBottom: 0 }}>{this.props.label} </span>
            </label>
          </div>
          <div style={{textAlign: 'right'}}>
            <label style={{ verticalAlign: 'middle', fontWeight: 'bold', marginBottom: 0, marginRight: '5px' }}>
              <input type="radio" value="Yes" checked={this.state.answer == 1} onChange={this.handleChange} style={{ marginRight: "5px" }} />
            Yes
          </label>
            <label style={{ verticalAlign: 'middle', fontWeight: 'bold', marginBottom: 0 }}>
              <input type="radio" value="No" checked={this.state.answer == 0} onChange={this.handleChange} style={{ marginRight: "5px" }} />
            No
          </label>
          </div>
        </div>

      </>
    )
  }
}

export default Question